<template>
  <div>
    <vx-card class="mb-4">
      <vs-tabs :color="colorx">
        <vs-tab :label="$t('plan.title')">
          <div class="flex flex-col gap-4">
            <div class="w-full" v-if="isEdit()">
              <vs-input
                  id="plan_name"
                  class="w-full required"
                  :label="$t('plan.name')"
                  v-model="model.name"/>
            </div>
            <div class="w-full" v-if="!isEdit()">
              <vx-input-group>
                <vs-input
                  id="plan_name"
                  class="w-full required"
                  v-validate="'required|max:191'"
                  :label="$t('plan.name')"
                  v-model="model.name"/>
                <span class="text-danger text-sm" v-show="errors.has('plan_name')">{{errors.first('plan_name')}}</span>
                <template slot="append">
                  <div class="append-text btn-addon flex items-end">
                    <vs-button
                      color="primary"
                      type="border"
                      icon="search"
                      :title="$t('buscar')"
                      @click="showPlanPopup = true"></vs-button>
                  </div>
                </template>
              </vx-input-group>
              <plan-popup
                :visible="showPlanPopup"
                @selected="addPlanFromPopup"
                @close="closePlanPopup"/>
            </div>
            <div class="w-full" v-if="isEdit()">
              <vs-input icon-pack="material-icons" :icon="accountIcon"
                  id="account"
                  class="w-full"
                  :label="isInstitution? $t('plan.institution') : $t('plan.user')"
                  v-model="model.account_name"
                  readonly/>
            </div>
            <div class="w-full grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 gap-2" v-if="!isEdit()">
              <div class="flex items-center">
                <label :class="isInstitution ? 'text-primary' : ''">{{ $t('plan.institution') }}</label>
                <vs-switch class="bg-primary mr-2 ml-2" v-model="isInstitution"/>
                <label :class="isInstitution ? '' : 'text-primary'">{{ $t('plan.user') }}</label>
              </div>
              <div class="flex col-span-2" v-if="isInstitution">
                <select-suggestion
                  :max="20"
                  class="flex-grow required"
                  column="name"
                  model="Institution"
                  :label="$t('fields.institution')"
                  v-model="institution"
                  :appendClearOption="true"
                  ref="select_institution"
                  :placeholderText="$t('placeholders.select_suggestion.institution')"/>
                  <span class="text-danger text-sm" v-show="errors.has('institution')">{{errors.first('institution')}}</span>
              </div>
              <div class="flex col-span-2" v-if="!isInstitution">
              <select-suggestion
                class="flex-grow required"
                column="name,email"
                model="User"
                color="dark"
                :label="$t('usuario')"
                v-model="user"
                :appendClearOption="true"
                ref="select_user"
                :placeholderText="$t('placeholders.select_suggestion.user_email')"
                :itemTextFormat="(model) => `${model.name} <br/><div class=\'text-sm\'>${model.email}<div>`"
                  />

              </div>
            </div>
            <div class="w-full grid grid-cols-1 sm:grid-cols-4 md:grid-cols-4 gap-2">
              <div class="flex">
                <vs-select
                  id="plan_currency_type"
                  class="w-full sm:w-1/2 flex-shrink required"
                  :label="$t('plan.currency_type')"
                  v-validate="'required'"
                  v-model="model.currency_type">
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in currencyTypeList" />
                </vs-select>
                <span class="text-danger text-sm" v-show="errors.has('plan_currency_type')">{{errors.first('plan_currency_type')}}</span>
              </div>
              <div class="flex">
                <vs-input
                  id="plan_price_value"
                  lang="pt-BR"
                  class="required"
                  type="number"
                  v-validate="'required|numeric'"
                  :label="$t('billing.payment_value')"
                  v-model="model.payment_value"/>
                  <span class="text-danger text-sm" v-show="errors.has('plan_price_value')">{{errors.first('plan_price_value')}}</span>
              </div>
              <div class="flex">
                <vs-input
                  id="plan_implementation_value"
                  lang="pt-BR"
                  class="required"
                  type="number"
                  v-validate="'required|numeric'"
                  :label="$t('billing.implementation_value')"
                  v-model="model.implementation_value"/>
                  <span class="text-danger text-sm" v-show="errors.has('plan_implementation_value')">{{errors.first('plan_implementation_value')}}</span>
              </div>
              <div class="flex">
                <vs-input
                  id="plan_duration"
                  type="number"
                  v-validate="'numeric|max:3'"
                   maxlength="3"
                   @keypress="isNumberInteger"
                  :label="$t('plan.duration_in_day')"
                  v-model="model.duration"/>
                  <span class="text-danger text-sm" v-show="errors.has('plan_duration')">{{errors.first('plan_duration')}}</span>
              </div>
            </div>
            <div class="w-full">
              <label class="text-sm pl-1">{{ $t('plan.description') }}</label>
              <complex-editor
                v-bind:editor_data.sync="model.description"/>
            </div>
            <div class="w-full flex gap-2 justify-end">
              <vs-button
                v-if="!isEdit()"
                :disabled="!validateForm"
                v-permission="'billing.create'"
                @click="createOrUpdate">
                {{ $t('action.save') }}
              </vs-button>
              <vs-button
                v-if="isEdit()"
                :disabled="!validateForm"
                v-permission="'billing.edit'"
                @click="createOrUpdate">
                {{ $t('action.save') }}
              </vs-button>
              <vs-button
                type="border"
                @click="cancel">
                {{ $t('common.back') }}
              </vs-button>
            </div>
          </div>
        </vs-tab>
        <vs-tab :label="$t('plan.usage')" v-if="isEdit()">
          <div class="flex flex-col gap-4">
            <PlanUsageForm :plan_billing_id="id"/>
          </div>
          <div class="w-full flex gap-2 pt-6 justify-end">
            <vs-button
              type="border"
              @click="cancel">
              {{ $t('common.back') }}
            </vs-button>
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'
import { isNumberInteger } from '@/util/NumberUtil'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import ComplexEditor from '../../questionnaires/elements/ComplexEditor.vue'

import PlanPopup from './../PlanPopup.vue'
import PlanUsageForm from './PlanUsageForm.vue'

import PlanBillingService from '@/services/api/PlanBillingService'


export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectSuggestion,
    ComplexEditor,
    PlanPopup,
    PlanUsageForm
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    institution: null,
    user: null,
    isInstitution: true,
    showPlanPopup: false,
    model: {
      id: null,
      plan_id: null,
      is_institution: true,
      name : '',
      currency_type:  null,
      payment_value: '',
      implementation_value: '',
      duration: '',
      description: '',
    },
    currencyTypeList:  [
        {text: 'BRL', value: 'BRL'},
      ],
  }),
  computed: {
    validateForm() {
      return  (!this.isEmpty(this.model.name)
                && !this.isEmpty(this.model.plan_id)
                && !this.isEmpty(this.model.currency_type)
                && !this.isEmpty(this.model.payment_value)
                && ((this.isInstitution && this.institution != null && this.institution.id != null)
                    || (!this.isInstitution && this.user != null && this.user.id != null))
              )
    },
    accountIcon() {
      return this.isInstitution ? 'account_balance' : 'person'
    }
  },
  beforeMount() {
    this.service = PlanBillingService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
    }
  },
  methods: {
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    },
    loadData(id) {
      this.$vs.loading()

      this.service.read(id).then(
        response => {
          this.populateModels(response)
        },
        error => {
          this.$vs.loading.close()
        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    populateModels(data){
      this.model = {
        id: data.id,
        name: data.name,
        plan_id: _.get(data, 'plan_id'),
        description: _.get(data, 'description'),
        currency_type: _.get(data, 'currency_type'),
        payment_value: _.get(data, 'payment_value'),
        implementation_value: _.get(data, 'implementation_value'),
        duration: _.get(data, 'duration'),
        account_name: _.get(data, 'account_name'),
        is_institution: _.get(data, 'is_institution'),
      }

      //Institution
      this.isInstitution = _.get(data, 'is_institution')

      const account_id = _.get(data, 'account_id')
      const account_name = _.get(data, 'account.name')

      if(this.isInstitution){
        this.institution = {
          id: account_id,
          name: account_name
        }
        this.user = null
      }else{
        this.user = {
          id: account_id,
          name: account_name
        }
        this.institution = null
      }
    },
    createOrUpdate() {
      this.$vs.loading()

      const modelData = {
        id: this.model.id,
        plan_id: this.model.plan_id,
        name: this.model.name,
        description: this.model.description,
        currency_type: this.model.currency_type,
        payment_value: this.model.payment_value,
        implementation_value: this.model.implementation_value,
        duration: this.model.duration,
        account_id: this.institution ? _.get(this.institution, 'id') : _.get(this.user, 'id'),
        account_name: this.institution ? _.get(this.institution, 'name') : _.get(this.user, 'name'),
        is_institution: this.isInstitution,
      }

      this.service.createOrUpdate(modelData).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          if(this.model.id === null){ //Origin - insert
            this.$router.push(`/billing/${data.id}/edit`)
          }
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    addPlanFromPopup(entity){
      this.model.plan_id = entity.id
      this.model.name = entity.name
      this.model.currency_type = entity.currency_type
      this.model.payment_value = entity.value
      this.model.description = entity.description
    },
    closePlanPopup(show){
      this.showPlanPopup = show
    },
    isAdmin() {
      return isAdmin()
    },
    isEdit() {
      return !_.isNil(this.id)
    },
    cancel() {
      this.$router.push('/billing')
    },
    isNumberInteger(val){
      return isNumberInteger(val)
    }
  }
}
</script>

<style>

</style>
