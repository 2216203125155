<template>
  <div>
    <vs-table :data="planUsageList"
      :no-data-text="$t('nenhum-registro-encontrado')">
        <template slot="thead">
          <vs-th width="30%">{{ $t('plan.type') }}</vs-th>
          <vs-th width="20%">{{ $t('plan.start') }}</vs-th>
          <vs-th width="20%">{{ $t('plan.finish') }}</vs-th>
          <vs-th width="10%">{{ $t('plan.total') }}</vs-th>
          <vs-th width="10%">{{ $t('plan.usage') }}</vs-th>
          <vs-th width="10%">{{ $t('acao') }}</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="data[i]" v-for="(tr, i) in data" :style="rowStyle(data[i])">
            <vs-td>
              {{$t(`plan.limit.${data[i].type.name}`)}}
            </vs-td>
            <vs-td>
              {{data[i].start_at_fmt}}
            </vs-td>
            <vs-td>
              {{data[i].finish_at_fmt}}
            </vs-td>
            <vs-td>
              {{data[i].total}}
            </vs-td>
            <vs-td>
              {{data[i].count}}
            </vs-td>
            <vs-td>
              <span class="flex">
                <feather-icon
                  v-permission="'billing.edit'"
                  icon="EditIcon"
                  style="cursor: pointer"
                  svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                  @click="edit(data[i])"/>
                <feather-icon
                  v-permission="'billing.delete'"
                  icon="Trash2Icon"
                  style="cursor: pointer"
                  svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
                  @click="confirmDelete(data[i])"/>
              </span>
            </vs-td>
          </vs-tr>
        </template>
    </vs-table>
    <vs-popup :title="titlePopupLimit" :active.sync="showPopupLimit" id="popup-entity" @close="closePopup" ref="vspopup-per">
      <div class="w-full grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 gap-2">
        <div>
          <label for="plan_period_finish">{{ $t('plan.start') }}</label>
          <div class="flex">
              <datetime
                id="plan_period_start"
                v-model="model.start_at"
                input-class="vs-inputx vs-input--input normal required"
                :phrases="{ok: 'Ok', cancel: 'Cancelar'}"
                type="date"
                format="dd/MM/yyyy"
                value-zone="America/Sao_Paulo"
                class="flex-auto w-8/12 mr-1 tour-config-4"
                :placeholder="$t('data')">
            </datetime>
            <span class="text-danger text-sm" v-show="errors.has('plan_period_start')">{{errors.first('plan_period_start')}}</span>
          </div>
        </div>
        <div>
          <label for="plan_period_finish">{{ $t('plan.finish') }}</label>
          <div class="flex">
            <datetime
                id="plan_period_finish"
                v-model="model.finish_at"
                input-class="vs-inputx vs-input--input normal required"
                :phrases="{ok: 'Ok', cancel: 'Cancelar'}"
                type="date"
                format="dd/MM/yyyy"
                value-zone="America/Sao_Paulo"
                class="flex-auto w-8/12 mr-1 tour-config-4"
                :placeholder="$t('data')">
            </datetime>
            <span class="text-danger text-sm" v-show="errors.has('plan_period_finish')">{{errors.first('plan_period_finish')}}</span>
          </div>
        </div>
        <div class="flex w-1/5">
          <vs-input
              lang="pt-BR"
              type="number"
              class="required"
              v-validate="'required|numeric'"
              :label="$t('plan.total')"
              v-model="model.total"/>
        </div>
      </div>
      <div class="w-full flex gap-2 justify-end pt-6">
        <vs-button
          :disabled="!validateLimitForm"
          v-permission="'billing.edit'"
          @click="savePlanUsage">
          {{ $t('action.save') }}
        </vs-button>
      </div>
    </vs-popup>
    <div class="w-full gap-2 p-3">
        <vs-button
          @click="showPopupLimitType = true"
          icon="add"
          type="flat"
          class="float-right mb-3">
          {{ $t('adicionar') }}
        </vs-button>
        <vs-divider/>
        <PlanLimitTypePopup
          :visible="showPopupLimitType"
          @selected="addLimitTypesList"
          @close="closePopupLimitType"/>
    </div>
  </div>
</template>
<script>

import standard from '@/services/standard'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import Moment from 'moment'

import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import PlanLimitTypePopup from './../PlanLimitTypePopup.vue'

import PlanBillingService from '@/services/api/PlanBillingService'

export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    Datetime,
    standard,
    SelectSuggestion,
    PlanLimitTypePopup
  },
  props: {
    plan_billing_id: {
      default: null,
      type: Number
    }
  },
  data: () => ({
    service: null,
    planUsageList: [],
    showPopupLimit: false,
    showPopupLimitType: false,
    model: {
      id: null,
      start_at: null,
      finish_at: null,
      total: null,
      limit_type_name: '',
    },
  }),
  computed: {
    validateLimitForm(){
      return  ( //!this.isEmpty(this.model.start_at) &&
                //!this.isEmpty(this.model.finish_at) &&
                !this.isEmpty(this.model.total))
    },
    titlePopupLimit(){
      return this.$t('plan.limits') + ' - ' + this.$t('plan.limit.'+this.model.limit_type_name)
    }
  },
  beforeMount() {
    this.service = PlanBillingService.build(this.$vs)
  },
  mounted() {
    if (this.plan_billing_id) {
      this.loadData()
    }
  },
  methods: {
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    },
    loadData() {
      this.$vs.loading()
      const planBillingId = this.plan_billing_id

      this.service.getPlanUsageList(planBillingId).then(
        response => {
          this.populateModels(response)
        },
        error => {
          this.$vs.loading.close()
        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    edit(data){
      this.model.id = data.id
      this.model.start_at = data.start_at ? Moment(data.start_at, 'YYYY-MM-DD').toDate().toISOString() : ''
      this.model.finish_at = data.finish_at ? Moment(data.finish_at, 'YYYY-MM-DD').toDate().toISOString(): ''

      this.model.total = data.total
      this.model.limit_type_name = data.type.name
      this.showPopupLimit = true
    },
    closePopup(show) {
      this.showPopupLimit = show
      this.clearModel()
    },
    addLimitTypesList(list = null) {
      if (list && this._.isArray(list) && list.length > 0) {
        this.addLimitTypes(list)
      }
    },
    addLimitTypes(list){
      let listLimit = []

      //Handling data to not repeat
      list.forEach((limitType) => {
        const found = this.planUsageList.find((entity)=>{
            return entity.type.id === limitType.id
        })

        if(!found){
          listLimit.push({
            limit_type_id: limitType.id,
            plan_billing_id: this.plan_billing_id
          })
        }
      })

      //Save
      this.savePlanUsageLimits(listLimit)

    },
    closePopupLimitType(show) {
      this.showPopupLimitType = show
    },
    clearModel(){
        this.model = {
          id: null,
          start_at: null,
          finish_at: null,
          total: null,
          limit_type_name: '',
        }
    },
    savePlanUsage(){
      this.$vs.loading()
      const planUsageId = this.model.id

      this.service.savePlanUsage(this.plan_billing_id, planUsageId, this.model).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.loadData()
          this.clearModel()
          this.closePopup()
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    savePlanUsageLimits(list){
      this.$vs.loading()

      this.service.savePlanUsageLimits(this.plan_billing_id, list).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.loadData()
          this.closePopupLimitType()
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    confirmDelete(data){
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-excluir-este-registro') +' '+ data.type.name,
        accept: ()=> {this.destroyData(data)}
      })
    },
    destroyData(entity){
      this.service.deletePlanUsage(this.plan_billing_id, entity.id).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))

          this.loadData()
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )

    },
    populateModels(data){
      this.planUsageList = data
    },
    isAdmin() {
      return isAdmin()
    },
    isEdit() {
      return !_.isNil(this.id)
    },
    cancel() {
      this.$router.push('/billing')
    },
    rowStyle(entry) {
      if (entry.count >= entry.total) return 'color: #ff6000'
    }
  }
}
</script>

<style>

</style>
