<template>
    <vs-popup :title="$t('plan.title')" :active.sync="visible" id="popup-entity" @close="closePopup" ref="vspopup-per">
      <Grid
          ref="grid-popup"
          :service="service"
          route_name="plans"
          route_grid_path="/grid"
          hide_actions="true"
          order_column="name"
          :delegate="{itemSelected: () => handleSelection} "
          selectObject="true"
          :request-params="filtersGrid"
          v-if="visible">
        <template v-slot:gridThead>
          <vs-th key="id">ID</vs-th>
          <vs-th key="name">{{ $t('plan.name') }}</vs-th>
          <vs-th key="value_fmt">{{ $t('plan.price_value') }}</vs-th>
        </template>
        <template v-slot:gridData="props">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
            <vs-td width="5%" key="id">
              <div v-html-safe="tr.id"></div>
            </vs-td>
            <vs-td width="65%" key="name">
              <div v-html-safe="tr.name"></div>
            </vs-td>
            <vs-td width="30%" key="value">
              <div> {{formatValue(_.get(tr, 'value'))}}</div>
            </vs-td>
          </vs-tr>
        </template>
        </Grid>
    </vs-popup>
</template>

<script>
import { currencyFormatter } from '@/util/Util'
import PlansService from '@/services/api/PlansService'

export default {
  components: {

  },
  props: {
    visible :{
      type: Boolean,
      default: false
    },
    filters :{
      type: String,
      default: null
    }
  },
  data: () => ({
    service: null,
  }),
  computed: {
    filtersGrid(){
      return this.filters
    }
  },
  methods: {
    handleSelection(model) {
      this.$emit('selected', model)
      this.closePopup(false)
    },
    closePopup(value){
      this.$emit('close', value)
    },
    applyFiltersGrid(){
      if(this.filters){
        this.getGrid().fillCustomFilters(this.filters)
        this.getGrid().fetchGridData(false)
      }
    },
    getGrid(){
      const gripP = this.$refs['grid-popup']
      return gripP
    },
    formatValue(value){
      return currencyFormatter(value)
    }
  },
  beforeMount() {
    this.service = PlansService.build(this.$vs)
  },
  mounted() {

  },
  watch: {
    visible(val){
      if(val){
        //this.applyFiltersGrid()
      }
    }
  },
}
</script>


<style lang="scss" scoped>
  ::v-deep .vs-popup {
    width: 800px !important;
  }
</style>
