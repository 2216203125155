var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-table",
        {
          attrs: {
            data: _vm.planUsageList,
            "no-data-text": _vm.$t("nenhum-registro-encontrado"),
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var data = ref.data
                return _vm._l(data, function (tr, i) {
                  return _c(
                    "vs-tr",
                    { key: data[i], style: _vm.rowStyle(data[i]) },
                    [
                      _c("vs-td", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("plan.limit." + data[i].type.name)) +
                            " "
                        ),
                      ]),
                      _c("vs-td", [
                        _vm._v(" " + _vm._s(data[i].start_at_fmt) + " "),
                      ]),
                      _c("vs-td", [
                        _vm._v(" " + _vm._s(data[i].finish_at_fmt) + " "),
                      ]),
                      _c("vs-td", [_vm._v(" " + _vm._s(data[i].total) + " ")]),
                      _c("vs-td", [_vm._v(" " + _vm._s(data[i].count) + " ")]),
                      _c("vs-td", [
                        _c(
                          "span",
                          { staticClass: "flex" },
                          [
                            _c("feather-icon", {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "billing.edit",
                                  expression: "'billing.edit'",
                                },
                              ],
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                icon: "EditIcon",
                                svgClasses:
                                  "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.edit(data[i])
                                },
                              },
                            }),
                            _c("feather-icon", {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "billing.delete",
                                  expression: "'billing.delete'",
                                },
                              ],
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                icon: "Trash2Icon",
                                svgClasses:
                                  "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmDelete(data[i])
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                })
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", { attrs: { width: "30%" } }, [
                _vm._v(_vm._s(_vm.$t("plan.type"))),
              ]),
              _c("vs-th", { attrs: { width: "20%" } }, [
                _vm._v(_vm._s(_vm.$t("plan.start"))),
              ]),
              _c("vs-th", { attrs: { width: "20%" } }, [
                _vm._v(_vm._s(_vm.$t("plan.finish"))),
              ]),
              _c("vs-th", { attrs: { width: "10%" } }, [
                _vm._v(_vm._s(_vm.$t("plan.total"))),
              ]),
              _c("vs-th", { attrs: { width: "10%" } }, [
                _vm._v(_vm._s(_vm.$t("plan.usage"))),
              ]),
              _c("vs-th", { attrs: { width: "10%" } }, [
                _vm._v(_vm._s(_vm.$t("acao"))),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          ref: "vspopup-per",
          attrs: {
            title: _vm.titlePopupLimit,
            active: _vm.showPopupLimit,
            id: "popup-entity",
          },
          on: {
            "update:active": function ($event) {
              _vm.showPopupLimit = $event
            },
            close: _vm.closePopup,
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-full grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 gap-2",
            },
            [
              _c("div", [
                _c("label", { attrs: { for: "plan_period_finish" } }, [
                  _vm._v(_vm._s(_vm.$t("plan.start"))),
                ]),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("datetime", {
                      staticClass: "flex-auto w-8/12 mr-1 tour-config-4",
                      attrs: {
                        id: "plan_period_start",
                        "input-class":
                          "vs-inputx vs-input--input normal required",
                        phrases: { ok: "Ok", cancel: "Cancelar" },
                        type: "date",
                        format: "dd/MM/yyyy",
                        "value-zone": "America/Sao_Paulo",
                        placeholder: _vm.$t("data"),
                      },
                      model: {
                        value: _vm.model.start_at,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "start_at", $$v)
                        },
                        expression: "model.start_at",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("plan_period_start"),
                            expression: "errors.has('plan_period_start')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("plan_period_start")))]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("label", { attrs: { for: "plan_period_finish" } }, [
                  _vm._v(_vm._s(_vm.$t("plan.finish"))),
                ]),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("datetime", {
                      staticClass: "flex-auto w-8/12 mr-1 tour-config-4",
                      attrs: {
                        id: "plan_period_finish",
                        "input-class":
                          "vs-inputx vs-input--input normal required",
                        phrases: { ok: "Ok", cancel: "Cancelar" },
                        type: "date",
                        format: "dd/MM/yyyy",
                        "value-zone": "America/Sao_Paulo",
                        placeholder: _vm.$t("data"),
                      },
                      model: {
                        value: _vm.model.finish_at,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "finish_at", $$v)
                        },
                        expression: "model.finish_at",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("plan_period_finish"),
                            expression: "errors.has('plan_period_finish')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("plan_period_finish")))]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "flex w-1/5" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|numeric",
                        expression: "'required|numeric'",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      lang: "pt-BR",
                      type: "number",
                      label: _vm.$t("plan.total"),
                    },
                    model: {
                      value: _vm.model.total,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "total", $$v)
                      },
                      expression: "model.total",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "w-full flex gap-2 justify-end pt-6" },
            [
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "billing.edit",
                      expression: "'billing.edit'",
                    },
                  ],
                  attrs: { disabled: !_vm.validateLimitForm },
                  on: { click: _vm.savePlanUsage },
                },
                [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "w-full gap-2 p-3" },
        [
          _c(
            "vs-button",
            {
              staticClass: "float-right mb-3",
              attrs: { icon: "add", type: "flat" },
              on: {
                click: function ($event) {
                  _vm.showPopupLimitType = true
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("adicionar")) + " ")]
          ),
          _c("vs-divider"),
          _c("PlanLimitTypePopup", {
            attrs: { visible: _vm.showPopupLimitType },
            on: {
              selected: _vm.addLimitTypesList,
              close: _vm.closePopupLimitType,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }